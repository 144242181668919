import React from "react"
import Header from "../components/header"
import Layout from "../components/layout"
import { Footer } from "../components/footer"

const TakkPage = () => {

  return <Layout>
    <div className="bg-gray-100">
      <div className="relative pt-6 pb-8">
        <Header />
      </div>
      <div className="relative pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-center text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            Takk for din henvendelse
          </h1>
        </div>
      </div>
    </div>
    <div className="bg-gray-900 text-gray-200">
      <Footer />
    </div>
  </Layout>
}

export default TakkPage
